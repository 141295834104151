<template>
    <div class="page-factory-index">
        <div
            class="user-header"
            @click="switchAccount"
        >
            <div class="img-box">
                <img
                    :src="ossHost + 'img/logo_min.png'"
                    alt=""
                >
            </div>
            <span>{{name}}</span>
            <span class="switch-account">切换账号</span>
        </div>
        <div class="scan-body">
            <!-- <div class="scan-box" @click="scan" >
                <img :src="ossHost + 'img/scan.png'" alt="" >
            </div>
            <div class="content-title">使用流程</div>
            <div class="content-text">1.点击上方的“扫码”按钮，对准设备上的二维码；</div>
            <div class="content-text">2.扫描成功后，进入质检流程；</div>
            <div class="content-text">3.质检通过后，确认并勾选信号和气泵的两个检查项；</div>
            <div class="content-text">4.点击“质检通过”，即代表该设备已通过出厂质检。</div> -->
            <div class="btn" @click="scan" v-if="type == 3 || type == 5"><img :src="ossHost + 'img/icon_zhijian.png'" alt="">扫码质检</div>
            <div class="btn" @click="vieProbeList" v-if="type == 2"><img :src="ossHost + 'img/icon_zhijian.png'" alt="">探头出厂质检</div>
            <div class="btn" @click="viewQualityList" v-if="type == 5"><img :src="ossHost + 'img/out_stock.png'" alt="">质检记录</div>
            <div class="btn dingsun" @click="viewQualityList(1)" v-if="type == 3 || type == 1 || type == 5 || type == 6"><img :src="ossHost + 'img/icon_dingsun.png'" alt=""> <span v-if="count">{{count}}</span> 返厂定损</div>
            <div class="btn dingsun" @click="viewQualityList" v-if="type == 2"><img :src="ossHost + 'img/icon_dingsun.png'" alt="">登记返厂探头</div>
            <div class="btn" @click="viewDescription" v-if="type == 3 || type == 5 || type == 6"><img :src="ossHost + 'img/icon_description.png'" alt="">使用说明</div>

            <div class="btn" @click="showModal = true" v-if="type == 4 && !yType"><img :src="ossHost + 'img/icon_zhijian.png'" alt="">复核维护订单</div>
            <div class="btn dingsun" @click="viewWarningList" v-if="type == 4 && !yType"><img :src="ossHost + 'img/icon_dingsun.png'" alt="">核对异常探头</div>
            <div class="btn dingsun" @click="onclickOutStock" v-if="type == 4 && yType == 1"><img :src="ossHost + 'img/out_stock.png'" alt="">出库设备校验</div>
            <div class="logo-box">
                <img
                    :src="ossHost + 'img/logo.png'"
                    alt=""
                >
            </div>
        </div>

        <Popup v-model="showModal" @close="probeId = ''">
            <div class="modal-box">
                <div class="modal-header">请输入探头ID</div>
                <div class="modal-body">
                    <Field v-model="probeId" type="number" input-align="center" placeholder="请输入探头ID"/>
                </div>
                <div class="modal-footer">
                    <div :class="{'btn': true, 'pass': isPass}" @click="loadProbeInfo">确认</div>
                </div>
            </div>
        </Popup>

        <Popup v-model="showSetupModal" position="center" class="tl-setup-modal">
            <div class="modal-setup-body">
                <div class="model-header">
                    <img :src="`${ ossHost }img/icon-tl-box.jpg`" alt="">
                </div>
                <div class="model-header">{{ qaInfo.uniqueId }}</div>

                <div class="kzh-qa-item" @click="onclickResetQABtn(1, qaInfo.hasKzhQc)">
                    <Icon name="passed" v-if="qaInfo.hasKzhQc" />
                    <Icon name="info" v-else />

                    <div class="item-info">功能质检</div>
                    <div class="item-btn" v-if="qaInfo.hasKzhQc">重检</div>
                    <div class="item-btn" v-else>开始</div>
                </div>

                <div class="kzh-qa-item" @click="onclickResetQABtn(2, qaInfo.hasKzhWeighQc)">
                    <Icon name="passed" v-if="qaInfo.hasKzhWeighQc" />
                    <Icon name="info" v-else />

                    <div class="item-info">
                        <div class="item-info-title">称重质检</div>
                        <div class="item-info-text">仅称重套装需做此步骤</div>
                    </div>
                    <div class="item-btn" v-if="qaInfo.hasKzhWeighQc">重检</div>
                    <div class="item-btn" v-else>开始</div>
                </div>
            </div>
        </Popup>
        <Popup v-model="showReQuality">
            <div class="tip-modal-box">
                <div class="modal-title">该控制盒已进行过质检</div>
                <div class="modal-content">编号{{ qaInfo.uniqueId }}的控制盒已进行过出厂质检</div>
                <div class="modal-btn">
                    <div class="btn-item" @click="showReQuality = false">取 消</div>
                    <div class="btn-item confirm" @click="onclickResetFsBtn">再次质检</div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Dialog, Popup, Field, Toast, Icon } from "vant";
export default {
    name: "FactoryIndex",
    components: {
        Popup,
        Field,
        Icon
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            name: "",
            meta: {
                title: '1234'
            },
            // 瑞银： 1
            // 乐行： 2
            // 豪派： 6
            type: 0,
            //  待质检数量
            count: 0,
            showModal: false,
            probeId: '',
            // 库房：1
            yType: '',
            showSetupModal: false,
            // 设备质检信息
            qaInfo: {},
            showReQuality: false
        };
    },

    computed: {
        isPass() {
            return Number(this.probeId) > 0 && this.probeId.length > 6
        }
    },

    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        const { yType } = vm.$route.query
        const uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid');
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (vm.$route.query.uuid) {
            vm.$localStorage.setItem('uuid', vm.$route.query.uuid)
        }

        if (name && fType) {
            vm.name = name;

            if (["lexing", "ruiyin", "yingwo", "lansong", "wangbo", "haopai"].indexOf(fType) == -1) {
                vm.$router.replace({
                    path: "404"
                });
                return;
            } else {
                let type = 0
                fType == 'ruiyin' && (type = 1)
                fType == 'yingwo' && (type = 2)
                fType == 'lexing' && (type = 3)
                fType == 'lansong' && (type = 4)
                fType == 'wangbo' && (type = 5)
                fType == 'haopai' && (type = 6)
                vm.type = type;
                [1,3,5,6].indexOf(type) > -1 && vm.loadCount()
                window.fTypeText = fType;
                window.fType = type;
            }
        } else {
            vm.$localStorage.setItem(USER_NAME, "");
            vm.$localStorage.setItem("uuid", "");
            vm.$router.replace({
                path: "404"
            });
        }
        vm.yType = yType
        vm.weixinInit();
    },

    methods: {
        switchAccount() {
            const vm = this;
            Dialog.confirm({
                title: "提示",
                message: "是否确认退出当前账号？",
                confirmButtonColor: "#32BE32"
            })
                .then(() => {
                    const { USER_NAME } = vm.$config.keys;
                    vm.$localStorage.removeItem(USER_NAME);
                    vm.$router.replace({
                        path: "/factory/login"
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },

        loadProbeInfo() {
            const vm = this
            if (!vm.isPass) {
                return
            }
            vm.tt = Toast.loading({
                message: '加载中',
                mask: true
            })
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo`,
                params: {
                    probeId: vm.probeId
                }
            }).then(res => {
                vm.tt.clear()
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                if (res.data.status != 1) {
                    Toast.fail('该探头暂无待复核状态订单')
                    return
                }
                vm.showModal = false
                vm.$router.push({
                    path: "./afterSale/determineRes",
                    query: {
                        pId: res.data.id
                    }
                });
            });
        },

        weixinInit() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `https://m.yudada.com/jssdk/configuration?url=${
                    location.href
                }`
            }).then(res => {
                const { appId, nonceStr, signature, timestamp } = res.data;
                window.wx.config({
                    debug: false,
                    appId,
                    timestamp,
                    nonceStr,
                    signature,
                    jsApiList: ["scanQRCode"]
                });
            });
        },

        vieProbeList() {
            this.$router.push({
                path: "/ryProbe/list"
            });
        },

        viewWarningList() {
            this.$router.push({
                path: "./afterSale/acceptanceCheckWarningList"
            });
        },

        scan() {
            const vm = this
            window.wx.scanQRCode({
                needResult: 1,
                scanType: ["qrCode"],
                success: function(res) {
                    const result = res.resultStr;
                    const uuid = result.split('deviceId=')[1]
                    if (!uuid) {
                        Dialog.confirm({
                            title: "提示",
                            message: "非设备二维码~",
                            confirmButtonColor: "#32BE32",
                            showCancelButton: false
                        })
                        return
                    }

                    // 旺博投料机控制盒质检操作
                    if (window.fType == 5) {
                        vm.loadDeviceInfo(encodeURI(uuid))
                        return
                    }

                    vm.$router.push({
                        path: "/factory/checkInfo",
                        query: { uuid: encodeURI(uuid) }
                    });
                }
            });
        },

        // 扫码获取设备信息
        loadDeviceInfo(uuid) {
            const vm = this
            vm.uuid = uuid;
            vm.tt = Toast.loading({
                message: '加载中',
                mask: true
            })
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}device/info`,
                params: {
                    encodeId: vm.uuid
                }
            }).then(res => {
                vm.tt.clear();
                const { type, uniqueId, deviceId, hasKzhQc } = res.data;
                vm.qaInfo = res.data;
                // 风送投料机
                if ([40, 41, 42, 43, 44, 45].includes(type)) {
                    // 质检过的设备弹框提醒
                    if (hasKzhQc) {
                        vm.showReQuality = true;
                        return;
                    }
                    vm.$router.push({
                        path: `/factory/quality/tlQa/${deviceId}?uid=${uniqueId}&isFs=1&qaType=1`,
                    });
                    return
                }
                vm.showSetupModal = true;
            });
        },

        // 重新质检风送控制盒
        onclickResetFsBtn() {
            const vm = this;
            const { uniqueId, deviceId } = vm.qaInfo
            vm.$router.push({
                path: `/factory/quality/tlQa/${deviceId}?uid=${uniqueId}&isFs=1&qaType=1`,
            });
        },

        // 重新质检 type: 1控制盒,2称重质检
        onclickResetQABtn(type, status) {
            const vm = this
            const { uniqueId, deviceId } = vm.qaInfo

            // 重新检测，先清除质检状态
            if (status) {
                Dialog.confirm({
                    title: "提示",
                    message: "是否需要重新质检？",
                    confirmButtonColor: "#32BE32"
                }).then(() => {
                    vm.tt = Toast.loading({
                        message: '加载中',
                        mask: true
                    })
                    vm.$http({
                        type: "put",
                        data: {
                            password: vm.$config.base.password,
                            userName: vm.name,
                            deviceIds: [deviceId],
                            operation: 1,
                            type
                        },
                        url: `${vm.$config.base.PROBE_BASE_URL}tl/kzh/qualityCheck`,
                        params: {
                            encodeId: vm.uuid
                        }
                    }).then(() => {
                        vm.tt.clear();
                        vm.$router.push({
                            path: `/factory/quality/tlQa/${deviceId}?uid=${uniqueId}&qaType=${type}`,
                        });
                    })
                }).catch(() => {
                    // on cancel
                });
            } else {
                vm.$router.push({
                    path: `/factory/quality/tlQa/${deviceId}?uid=${uniqueId}&qaType=${type}`,
                });
            }
        },

        viewDescription() {
            this.$router.push({
                path: `/factory/description?type=${this.type}`
            });
        },

        viewQualityList(operation) {
            // 是否为返厂定损
            if (operation == 1) {
                window.isLoss = true;
            } else {
                window.isLoss = false;
            }
            this.$router.push({
                path: this.type == 2 ? `/factory/afterSale/uploadImgPage` : `/factory/quality/qualityList`
            });
        },

        loadCount() {
            const vm = this
            let deviceType = vm.type;
            // 投料机控制盒
            vm.type == 5 && (deviceType = 2);
            // 豪派 摄像头+测水盒
            vm.type == 6 && (deviceType = 56);
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}damageCount`,
                params: {
                    deviceType
                }
            }).then(res => {
                vm.count = res.data
            });
        },

        onclickOutStock() {
            this.$router.push({
                path: './stock/checkDevice'
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";
.page-factory-index {
    width: 100%;
    height: 100%;
    background: @themeColor;
    .user-header {
        position: relative;
        padding: 0.23rem 0.1rem 0 0.7rem;
        display: flex;
        height: 0.73rem;
        align-items: center;
        font-size: 0.2rem;
        color: @blank;
        justify-content: space-between;
        .img-box {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            background: @blank;
            left: 0.1rem;
            top: 0.23rem;
            line-height: 0.5rem;
            text-align: center;
            img {
                display: inline-block;
                width: 80%;
            }
        }

        .switch-account {
            font-size: 0.12rem;
        }
    }

    .scan-body {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 1.1rem;
        background: @blank;
        z-index: 1;
        border-radius: 0.25rem 0.25rem 0 0;
        padding: 0.4rem 0.2rem;

        .scan-box {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 auto;
            border-radius: 0.75rem;
            box-shadow: 0px 0px 8px 5px rgba(26, 26, 26, 0.1);
            text-align: center;
            img {
                display: inline-block;
                width: 60%;
                margin-top: 0.3rem;
            }
        }

        .content-title {
            color: @block1;
            font-size: 0.2rem;
            margin: 0.15rem 0;
            font-weight: 600;
        }

        .content-text {
            color: @textColor1;
            font-size: 0.18rem;
            margin-bottom: 0.13rem;
            line-height: 1.4;
        }

        .logo-box {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding-bottom: 20px;
            img {
                display: block;
                height: 0.35rem;
                margin: 0 auto;
            }
        }
        .btn {
            width: 100%;
            height: 0.8rem;
            border-radius: 0.4rem;
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
            margin-bottom: 40px;
            box-sizing: border-box;
            font-size: 0.3rem;
            line-height: 0.8rem;
            text-align: center;
            color: @textColor3;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 0.32rem;
                display: block;
                margin-right: 10px;
            }

            &.dingsun {
                position: relative;
                span {
                    position: absolute;
                    display: block;
                    width: 50px;
                    line-height: 0.26rem;
                    border-radius: 8px 8px 8px 0;
                    color: #fff;
                    background: #FB3D66;
                    right: 30px;
                    top: 10px;
                    font-size: 0.14rem;
                }
            }
        }
    }

    .van-popup {
        border-radius: 10px;
        .modal-box {
            width: 300px;
            padding: 30px;
            font-size: 0.22rem;
            color: @textColor11;
            font-weight: bold;
            border-radius: 10px;
            text-align: center;
            .modal-body {
                height: 230px;
                padding: 40px 0 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .van-field {
                    margin-top: 30px;
                    border-bottom: 1px solid @bgColor3;
                    font-size: 0.3rem;
                }
            }
            .modal-footer {
                .btn {
                    line-height: 50px;
                    color: @blank;
                    border-radius: 6px;
                    width: 80%;
                    margin: 0 auto;
                    border: 1px solid @textColor9;
                    background: @bgColor3;
                    border-color: @bgColor3;
                    &.pass {
                        background: @themeColor;
                        border-color: @themeColor;
                    }
                }
            }
        }
        .tip-modal-box {
            width: 300px;
            padding: 20px;
            font-size: 0.18rem;
            color: @textColor1;
            text-align: center;
            .modal-title {
                font-weight: bold;
                font-size: 0.2rem;
            }
            .modal-content {
                margin-top: 12px;
            }
            .modal-btn {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .btn-item {
                    height: 45px;
                    width: 110px;
                    border: 1px solid @textColor1;
                    border-radius: 24px;
                    line-height: 42px;
                    &.confirm {
                        border: 1px solid @themeColor;
                        background-color: @themeColor;
                        color: @blank;
                    }
                }
            }
        }
    }
    .tl-setup-modal {
        width: 300px;
        .modal-setup-body {
            padding: 20px;
            .model-header {
                text-align: center;
                font-size: 20px;
                padding-bottom: 10px;
                img {
                    width: 60px;
                }
            }

            .kzh-qa-item {
                display: flex;
                position: relative;
                padding: 10px 20px;
                border-radius: 10px;
                background: @bgColor2;
                padding-left: 40px;
                justify-content: space-between;
                height: 80px;
                align-items: center;
                box-sizing: border-box;
                margin-bottom: 20px;
                .van-icon {
                    position: absolute;
                    left: 10px;
                    font-size: 24px;
                    &-passed:before {
                        color: @themeColor;
                    }
                }
                .item-info {
                    font-weight: bold;
                    font-size: 0.16rem;
                    &-text {
                        font-weight: normal;
                        font-size: 0.12rem;
                    }
                }
            }
        }
    }
}
</style>


